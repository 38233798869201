import './App.css';
import React, { useState, useEffect } from 'react';
import { Scale, Note } from 'tonal';
import * as Tone from 'tone';

function App() {
  const [synth, setSynth] = useState(new Tone.PolySynth(Tone.Synth).toDestination());
  const [key, setKey] = useState("C");
  const [scaleMode, setScaleMode] = useState("major");
  const [octave, setOctave] = useState(4);
  const [numOctaves, setNumOctaves] = useState(2);
  const [playedNotes, setPlayedNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);  // State to track currently animated note


  const clearNotes = () => {
    setPlayedNotes([]);
  }
  const playNote = (note) => {
    synth.triggerAttackRelease(note, "8n");
    setPlayedNotes(prevNotes => [...prevNotes, note]);
    setActiveNote(note);  // Set the currently active note
  };

  const keyOptions = Note.names(); 
  const scaleOptions = Scale.names().filter(name => !name.includes(' '));
  const octaveOptions = [2, 3, 4, 5, 6];
  const numOctavesOptions = [1, 2, 3];

  const getNotes = () => {
    const notes = [];
    for (let n = 0; n < numOctaves; n++) {
      const currentOctave = parseInt(octave) + n;
      Scale.get(`${key}${currentOctave} ${scaleMode}`).notes.forEach((note, index) => {
        if (n > 0 && index === 0) return;
        notes.push(note);
      });
    }
    return notes;
  };

  const keyboardMap = "ASDFGHJKLZXCVBNM".split('');
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      const char = event.key.toUpperCase();
      const index = keyboardMap.indexOf(char);
      const notes = getNotes();
      if (index !== -1 && index < notes.length) {
        playNote(notes[index]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [key, scaleMode, octave, numOctaves]); 

  useEffect(() => {
    if (activeNote !== null) {
      const timer = setTimeout(() => {
        setActiveNote(null);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [activeNote]);

  const getClassName = (note) => {
    let className = note.includes("#") ? "note sharp" : note.includes("b") ? "note flat" : "note";
    if (note === activeNote) {
      className += " active"; // Add 'active' class if the note is the currently animated one
    }
    return className;
  };


  const randomScale = () => {
    const randomKey = keyOptions[Math.floor(Math.random() * keyOptions.length)];
    const randomScale = scaleOptions[Math.floor(Math.random() * scaleOptions.length)];
    setKey(randomKey);
    setScaleMode(randomScale);
  };

  return (
    <div className="App">
      <select value={key} onChange={e => setKey(e.target.value)}>
        {keyOptions.map((keyOption, index) => (
          <option key={index} value={keyOption}>{keyOption}</option>
        ))}
      </select>
      <select value={scaleMode} onChange={e => setScaleMode(e.target.value)}>
        {scaleOptions.map((mode, index) => (
          <option key={index} value={mode}>{mode}</option>
        ))}
      </select>
      <button onMouseDown={() => randomScale()} >Random</button>
      <select value={octave} onChange={e => setOctave(e.target.value)}>
        {octaveOptions.map((oct, index) => (
          <option key={index} value={oct}>{oct}</option>
        ))}
      </select>
      <select value={numOctaves} onChange={e => setNumOctaves(e.target.value)}>
        {numOctavesOptions.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
      <div>
        {getNotes().map((note, index) => (
          <button key={index} className={getClassName(note)} onMouseDown={() => playNote(note)}>
            {note}
          </button>
        ))}
      </div>
      <div  onMouseDown={() => clearNotes()} className="clear-notes"> Clear Notes  </div>
      <div className="played-notes">
      {playedNotes.slice(-20).map((note, index) => (
          <span key={index}>{note}</span>
        ))}
      </div>
    </div>
  );
}

export default App;
